import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import Container from '../components/Container'
import styled from 'styled-components'
//import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { breakpoint, colors } from '../styles/variables'
import { Link } from 'gatsby'


const Wrapper = styled(Container)`
  min-height: 100vh;
  margin-top: 2rem;
  margin-bottom: 3rem;
  max-width: 700px;
  padding: 0 1rem 2rem;

  @media ${breakpoint.sm} {
    margin-top: 3rem;
  }

  @media ${breakpoint.md} {
    margin-top: 4.5rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoint.lg} {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
`

const Title = styled.h2 `
    margin-top: 0;
    text-transform: none;
    margin-bottom: 0.5em;
    font-size: 1.75rem;
`

const StyledFigure = styled.figure `
    margin: 1.5rem 0 2rem;

    @media ${breakpoint.md} {
        margin: 2rem 0 2.5rem;
    }
`

const StyledFigcaption = styled.figcaption `
    font-size: 0.875rem;
    color: gray;
    margin-top: 0.5em;
    text-align: right;
`

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
  margin-bottom: 2.5rem;
  display: inline-block;
  font-size: 0.875rem;
  transition: color 0.3s ease;

  @media ${breakpoint.lg} {
    margin-bottom: 4rem;
  }

  &:hover {
    color: ${colors.orangeHover};
  }

  .icon {
    margin-right: 0.5rem;
  }
`

const Date = styled.p`
    color: gray;
    margin: 0 0 2rem;
    font-size: 0.875rem;
`

const BlogpostPage = ({data, title}) => {

    return (
       <>
        <SmallBanner title="Blog"/>
        <Wrapper>
            <BackLink to="/blog">
                <span className="icon icon-arrow-left"></span>
                Zpět na výpis článků
            </BackLink>
            <Title>Není dobrodružství jako dobrodružství</Title>
            <Date>11. 9. 2022</Date>
            <p><b>Psal se prosinec 2018 a já se chystala na svou první velkou cestu do Asie. Plán zněl jasně: projet pár míst v Thajsku, Kambodži a Malajsii, které vybral kamarád na základě jeho předchozích zkušeností.</b></p>
            <p>Pro mě to tehdy byla první velká cestovní zkušenost. Letěla jsem sama z Prahy do Thajska (Suvarnabhumi), kde jsem měla strávit první den a noc. Tudíž jsem se z letiště musela dostat do města, tam přespat a ráno pokračovat na druhé letiště (Don Muang), odkud jsem měla letět na Ko Pha Ngan. Nevím, kdo tenkrát měl větší strach, jestli já (byla to moje první velká a dlouhá cesta), nebo taťka (jeho jediná malá, rozuměj dospělá 23letá, dcera se rozhodla vydat do Asie).</p>
            <p>Asi by bylo zbytečné popisovat průběh letu a každý můj krok. Vše proběhlo v naprostém pořádku: doletěla jsem, dojela jsem do centra, našla jsem hostel a večer jsem vyrazila do víru velkoměsta (Khao San Road - hlavní turistická ulice Bangkoku, kterou asi navštívil každý, kdo navštívil Bangkok). Druhý den jsem doletěla do Surat Thani, odkud jsem několik hodin pokračovala trajektem na ostrov Ko Pha Ngan, kde na mě čekali kamarádi. Došli jsme na místní trh na Pad Thai, pak jsem se ubytovala, půjčili jsme si skútry a jeli se projet. Vše zatím zní normálně a běžně, což by i bylo, kdyby má dosud jediná zkušenost se skútrem nebyla v 18 letech na dvoře u rodičů mého tehdejšího přítele. Matně si vybavuji, že velký květináč s Aloe Vera to odnesl.</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/thailand1.jpg" alt="Bangkok - Khao San Road"></StaticImage>
                <StyledFigcaption>(Foto by Julia Mandzyuk: Bangkok - Khao San Road)</StyledFigcaption>
            </StyledFigure>
            <p>Čekalo mě tedy druhé seznámení se skútrem. Aby toho nebylo málo, vůbec mi nedošlo, že se v Thajsku jezdí vlevo. Kdo by to zjišťoval dopředu, že? Já tenkrát rozhodně ne, ani jsem nikdy nezaznamenala historky o ošklivých nehodách turistů na skútrech. A i kdybych je slyšela… pravděpodobně bych odvětila: „Mně se přeci nic stát nemůže!“ Také se mi nic nestalo a vedla jsem si skvěle! Jak by řekla moje kamarádka Charlie - asi jsem “wunderkind” (což v němčině znamená zázračné dítě). <b>Samozřejmě nic netrvá věčně a vždy se něco musí pokazit.</b></p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/thailand2.jpg" alt="Thajsko - Ko Pha Ngan"></StaticImage>
                <StyledFigcaption>(Foto by Julia Mandzyuk: Thajsko - Ko Pha Ngan)</StyledFigcaption>
            </StyledFigure>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/thailand3.jpg" alt="Thajsko - Ko Pha Ngan, Phantip Night Food Market"></StaticImage>
                <StyledFigcaption>(Foto by Julia Mandzyuk: Thajsko - Ko Pha Ngan, Phantip Night Food Market)</StyledFigcaption>
            </StyledFigure>
            <p>Následující večer jsme se s kamarádkou rozhodly dojet do 7/11 (čti seven-eleven = místní řetězec odchodů) pro nějaké pití a něco na zub na večer. Byla už tma a naše ubytování bylo dole u pláže, což znamenalo vyjet velmi prudký kopec. Poslala jsem raději kamarádku pěšky napřed, nechtěla jsem jí ohrozit, kdybych náhodou spadla.</p>
	        <p>Cesta vzhůru šla vcelku hladce, zvládla jsem zdolat celý krpál, když mi najednou podklouzlo zadní kolo. Sklouzla jsem se skútrem do příkopu, kde shodou okolností spal místní vořech. <b>Představte si, že spíte a najednou vedle vás zazní rána… Co uděláte? Nevíte? Možná něco podobného, co udělal ten pes - jednoduše mě kousnul.</b> Já si to v tu chvíli ani neuvědomila, jen jsem zaznamenala štěknutí. Soustředila jsem se hlavně na to, abych bez újmy vytáhla plechového oře zpátky na silnici a dojela ke kamarádce. Až díky ní jsem zjistila, že mi teče krev.</p>
	        <p>Nic mě nebolelo, ale po příjezdu do 7/11 jsem raději požádala o dezinfekci. Ukázala jsem nohu a řekla, že to mám od psa. Konverzaci zaslechli dva místní chlapci, kteří se gestem zeptali, jestli jsem očkovaná. Samozřejmě, že ano: břišní tyfus, žloutenka A i B, tetanus… Jenže oni mysleli jiné očkování, což jsem pochopila z jejich výrazů a vrtění hlavou. <b>Aha, vzteklina! Tak proti té očkovaná nejsem…</b> Jasně, že jsem věděla, že něco jako vzteklina existuje. Akorát mě nenapadlo, že zrovna já se s ní potkám (koho by to také napadlo v Asii, kde je u toulavých zvířat naprosto běžná?).</p>
	        <p>Chlapci mi rovnou oznámili, že místo pití a něco dobrého na zub se večer podává Hospital. Ještě jsem se pro jistotu zeptala, jestli to je opravdu nutné. Bylo mi řečeno, že rozhodně hned. Tak teda jo, jedeme.</p>
	        <p>Dojeli jsme do nemocnice, kde jsem vylíčila, co se stalo. Položili mě na lůžko a doktor mi píchnul do nohy několik injekcí. Nevím co to bylo, ale bolelo to “jako pes”. Každopádně mi oznámili, že se nemůžu vrátit zpět do hotelu, ale musím zůstat v nemocnici přes noc. <b>Ach jo – mou třetí noc v Thajsku jsem si teda představovala jinak.</b></p>
          <StyledFigure>
                <StaticImage src="../assets/images/blog/thailand4.jpg" alt="Ko Pha Ngan - Nemocnice"></StaticImage>
                <StyledFigcaption>(Foto by Julia Mandzyuk: Ko Pha Ngan - Nemocnice)</StyledFigcaption>
            </StyledFigure>
	        <p>Večer jsem dostala několik kapaček a další injekce, vyčistili mi ránu a odvezli mě na pokoj, ať se vyspím. Po takové dávce různých léků se mi ale chtělo všechno, jenom ne spát. Vyhrála touha si povídat, a tak jsem nehledě na časový posun začala volat domů taťkovi a kamarádům. Sotva jsem po svítání zabrala, už mě vezli na sál na další čištění rány. <b>Nakonec jsem v nemocnici strávila 3 noci.</b></p>
          <StyledFigure>
                <StaticImage src="../assets/images/blog/thailand5.jpg" alt="Ko Pha Ngan - Nemocnice"></StaticImage>
                <StyledFigcaption>(Moje noha po cca týdnu)</StyledFigcaption>
            </StyledFigure>
	        <p>V rámci mého nemocničního pobytu také přišlo na řadu řešení pojištění. To jsem si před cestou zařídila, to zase jo. Střelec jsem všelijaký, <b>ale cestovní pojištění je “must have”</b>. Nakonec se zjistilo, že jsem pojištěná dokonce 2x díky zlaté firemní kartě, o které jsem ani netušila. Určitě se všichni shodneme, že lepší dvakrát než vůbec.</p>
	        <p>Nahlásila jsem na asistenční službu událost a obratem mi zavolala druhá pojišťovna, že se postará o veškeré potřebné výlohy a přidělí mi konkrétní číslo, na kterém vždy bude někdo, s kým mohu cokoliv řešit. Tak jo, proč ne, to zní dobře. Zeptali se mě, jestli se chci vrátit domů… rozhodně nechci! Alespoň si ode mě tedy vyžádali plán a itinerář cesty. Měla jsem v té době zarezervované všechny letenky i ubytování, takže nebyl problém jim vše poslat. Nakonec mi se souhlasem lékaře sdělili, že pokud se dostavím na převazy a očkování proti vzteklině, tak mohu v cestě pokračovat. <b>Hurá! Domů se nejede!</b></p>
	        <p>Dodržet pravidelné převazy znamenalo na ně chodit každý den po dobu dvou týdnů, v místech, kde se zrovna budu nacházet. Navíc jsem musela dostat zbytek očkovacích dávek v různých intervalech. Podle itineráře mi vyhledali nejbližší nemocnice a domluvili mi, kdy se kde ukážu. Super, budou už o mě dopředu vědět.</p>
	        <p>Třetí den v 5 ráno jsem opustila nemocnici s pomocí asistenta, který mě odvezl do přístavu a posadil na trajekt, kde jsem si konečně oddechla. Dostala jsem skvělý “Fit to Fly” papír (neboli jak jsem tomu říkala “kripl papír”) a s ofáčovanou nohou jsem opět seděla na letišti v Surat Thani a čekala na let do Siem Reapu (Cambodia). <b>Vstříc dalším zážitkům – ale o těch až příště.</b></p>
          <StyledFigure>
                <StaticImage src="../assets/images/blog/thailand6.jpg" alt="Pláž, Thajsko"></StaticImage>
          </StyledFigure>
        </Wrapper>
       </>
    )
  }

  export default BlogpostPage


  export const Head = () => (
    <>
      <title>Není dobrodružství jako dobrodružství | Blog | Cestu-j</title>
      <meta name="description" content="Cestovní itineráře na míru" />
    </>
  )